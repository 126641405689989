import { Http, http } from '@/libs/http';
import { SpuItemDto } from '@/types/esItem';
import { EsItemCommand, BatchSelectDto } from './type';
import { SkuWebDto } from '../skuCollocation/type';

export class ChatpeerRequest {
  private http: Http = http('/api/shop/item');

  esItemList(
    query: EsItemCommand
  ): Promise<{ items: SpuItemDto[]; totalResult: number }> {
    return this.http.path('search-v2').setRequestParams(query).get();
  }

  batchFetchList(query: {
    searchTaobaoIids: string;
    selectedTaobaoIids: string[];
  }): Promise<BatchSelectDto> {
    return this.http.path('batch-check-taobaoiid').setRequestBody(query).post();
  }

  getByTaoBaoIIds(query: {
    searchTaobaoIids: string[];
  }): Promise<SpuItemDto[]> {
    return this.http.path('get-by-taobao-iids-v2').setRequestBody(query).post();
  }

  skusByTaobaoIid(taobaoIid: string): Promise<SkuWebDto[]> {
    return this.http
      .path('skus-by-taobao-iid')
      .setRequestBody({ taobaoIid })
      .post();
  }
}

export default new ChatpeerRequest();
