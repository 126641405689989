import { act, flow, strict, Model, avatar } from 'agent-reducer';
import { ApproveStatus } from '@/types/approveStatus';
import { SkuWebDto, SpuItemDto, TaobaoIidskuids } from '@/types/esItem';
import { EsItemCommand } from '@/requests/chatpeer/type';
import chatpeerRequest from '@/requests/chatpeer';
import { warnError } from '@/components/infos';
import { noop } from '@/polyfill/func';

type State = {
  selectList: SpuItemDto[];
  selectSkuList: SkuWebDto[];
  dataSource: SpuItemDto[];
  page: number;
  pageSize: number;
  total: number;
  searchValue: string;
  searchApproveStatus: ApproveStatus | undefined;
  isEditing: boolean;
  modalAction: string;
  selectRecommendItem: string[];
};

const filterItems = (
  origin: SpuItemDto[],
  modalAction: string,
  selectRecommendItem: string[]
) =>
  origin.filter(i =>
    modalAction === 'addRecommendItem'
      ? !selectRecommendItem.includes(i.taobaoIid)
      : i
  );

@strict()
export class ItemSelectorModel implements Model<State> {
  state: State = {
    selectList: [], // 商品
    selectSkuList: [], // sku
    dataSource: [],
    page: 1,
    pageSize: 10,
    total: 0,
    searchValue: '',
    searchApproveStatus: 'ONSALE',
    isEditing: false,
    modalAction: '',
    selectRecommendItem: []
  };

  constructor({ items, skus }) {
    this.state.selectList = items;
    this.state.selectSkuList = skus;
  }

  interaction = avatar({
    changeItemsAndSkus(items: { items: SpuItemDto[]; skus: SkuWebDto[] }) {
      noop();
    }
  });

  @act()
  changeState(state: Partial<State>) {
    return { ...this.state, ...state };
  }

  @act()
  setKeyword(searchValue: string): State {
    return { ...this.state, searchValue };
  }

  @act()
  setEditing(editing: boolean): State {
    return { ...this.state, isEditing: editing };
  }

  @act()
  addItems(items: SpuItemDto[]): State {
    const newSelectList = [...new Set([...items, ...this.state.selectList])];

    this.interaction.current.changeItemsAndSkus({
      items: newSelectList,
      skus: this.state.selectSkuList
    });
    return { ...this.state, selectList: newSelectList };
  }

  @act()
  removeItem(id: string): State {
    const newSelectList = this.state.selectList.filter(
      item => item.taobaoIid !== id
    );

    this.interaction.current.changeItemsAndSkus({
      items: newSelectList,
      skus: this.state.selectSkuList
    });
    return { ...this.state, selectList: newSelectList };
  }

  @act()
  addSku(items: SkuWebDto[]): State {
    const newSelectList = [...new Set([...items, ...this.state.selectSkuList])];

    this.interaction.current.changeItemsAndSkus({
      items: this.state.selectList,
      skus: newSelectList
    });
    return { ...this.state, selectSkuList: newSelectList };
  }

  @act()
  removeSku(id: string): State {
    const newSelectList = this.state.selectSkuList.filter(
      item => item.taobaoSkuId !== id
    );

    this.interaction.current.changeItemsAndSkus({
      items: this.state.selectList,
      skus: newSelectList
    });
    return { ...this.state, selectSkuList: newSelectList };
  }

  @act()
  clearSku(taobaoIid: string): State {
    const { selectList, selectSkuList } = this.state;
    const newSelectSkuList = selectSkuList.filter(
      i => i.taobaoIid !== taobaoIid
    );
    this.interaction.current.changeItemsAndSkus({
      items: selectList,
      skus: newSelectSkuList
    });

    return { ...this.state, selectSkuList: newSelectSkuList };
  }

  @act()
  clearItem(): State {
    this.interaction.current.changeItemsAndSkus({
      items: [],
      skus: []
    });

    return { ...this.state, selectList: [], selectSkuList: [] };
  }

  @act()
  clearItemInStock(): State {
    const newSelectList = this.state.selectList.filter(
      item => item.approveStatus !== 'instock'
    );

    const newSelectSkuList = this.state.selectSkuList.filter(i =>
      newSelectList.map(j => j.taobaoIid).includes(i.taobaoIid)
    );

    this.interaction.current.changeItemsAndSkus({
      items: newSelectList,
      skus: newSelectSkuList
    });
    return {
      ...this.state,
      selectList: newSelectList,
      selectSkuList: newSelectSkuList
    };
  }

  @flow()
  async getSelectItems(taobaoIidskuids: TaobaoIidskuids[]) {
    const taobaoIids = [...new Set(taobaoIidskuids.map(i => i.taobaoIid))];
    // 只选了商品 无sku
    const onlySpu = taobaoIidskuids.flatMap(i =>
      !i.taobaoSkuId ? i.taobaoIid : []
    );
    const taobaoSpuList = await chatpeerRequest.getByTaoBaoIIds({
      searchTaobaoIids: taobaoIids
    });

    const skuIdSet = new Set(
      taobaoIidskuids.map(item => item.taobaoSkuId?.toString())
    );

    const selectList: SpuItemDto[] = [];
    const selectSkuList: SkuWebDto[] = [];
    taobaoSpuList.forEach(selectSpu => {
      if (onlySpu.includes(selectSpu.taobaoIid)) {
        selectList.push(selectSpu);
      }

      selectSpu.skuList.forEach(sku => {
        if (skuIdSet.has(sku.taobaoSkuId)) {
          selectSkuList.push(sku);

          if (
            !selectList.length ||
            !selectList.map(i => i.taobaoIid).includes(selectSpu.taobaoIid)
          ) {
            selectList.push(selectSpu);
          }
        }
      });
    });

    this.interaction.current.changeItemsAndSkus({
      items: selectList,
      skus: selectSkuList
    });
    this.changeState({ selectList, selectSkuList });
  }

  @flow()
  async fetchList({
    page,
    size
  }: {
    page?: number;
    size?: number;
  }): Promise<State> {
    try {
      const {
        pageSize,
        dataSource,
        page: currentPage,
        searchValue,
        searchApproveStatus,
        modalAction,
        selectRecommendItem
      } = this.state;
      const newPage = typeof page === 'number' ? page : currentPage;
      const params: EsItemCommand = {
        searchValue: searchValue ? searchValue.trim() : undefined,
        pageSize: size || pageSize,
        page: newPage,
        searchApproveStatus,
        lastModified:
          page !== 1 && dataSource.length
            ? dataSource[dataSource.length - 1].modified
            : undefined,
        lastTaobaoIid:
          page !== 1 && dataSource.length
            ? dataSource[dataSource.length - 1].taobaoIid
            : undefined
      };

      const { items, totalResult } = await chatpeerRequest.esItemList(params);

      if (page === 1) {
        this.changeState({
          ...this.state,
          dataSource: filterItems(items, modalAction, selectRecommendItem),
          page,
          total: totalResult
        });
        return this.state;
      }

      if (items.length === 0) {
        this.changeState({ ...this.state, total: totalResult });
        return this.state;
      }

      this.changeState({
        ...this.state,
        dataSource: filterItems(
          [...dataSource, ...items],
          modalAction,
          selectRecommendItem
        ),
        page,
        pageSize: size || pageSize,
        total: totalResult,
        searchApproveStatus
      });

      return this.state;
    } catch (error) {
      warnError(error);
    }

    return this.state;
  }

  @flow()
  async initial({
    modalAction,
    selectRecommendItem
  }: {
    modalAction: string;
    selectRecommendItem: string[];
  }): Promise<void> {
    this.changeState({ modalAction, selectRecommendItem });

    await this.fetchList({ page: 1 });
  }

  @flow()
  async handleApproveStatusChange(approveStatus: ApproveStatus) {
    this.changeState({ searchApproveStatus: approveStatus });

    await this.fetchList({ page: 1 });
  }
}
