import React, { memo, useEffect, useState } from 'react';
import { SpuItemDto, TaobaoIidskuids } from '@/types/esItem';
import chatpeerRequest from '@/requests/chatpeer';
import { Popover } from '@/components/widgets/popover';
import { VirtualDiv } from '@/components/layouts/virtualList';
import css from './style.less';
import { SpuItem } from './spuItemInfo';

export type GoodsPopProps = {
  title: string;
  label: string;
  taobaoIidskuids: TaobaoIidskuids[];
  className?: string;
};

export const NoDataContent = memo(({ noDataText }: { noDataText: string }) => (
  <div style={{ lineHeight: '40px', textAlign: 'center' }}>{noDataText}</div>
));

const TaobaoItemIdsInfo = memo(
  ({
    items,
    taobaoSkuIdSet
  }: {
    items: SpuItemDto[];
    taobaoSkuIdSet: Set<string | undefined>;
  }) => (
    <VirtualDiv
      className={css.scroll}
      dataSource={items || []}
      itemHeight={76}
      renderItem={item => (
        <SpuItem
          type="wait"
          item={item}
          key={item.taobaoIid}
          taobaoSkuIdSet={taobaoSkuIdSet}
        />
      )}
    />
  )
);

export default memo((props: GoodsPopProps) => {
  const { title = '', label = '', taobaoIidskuids = [], className } = props;
  const [items, setItems] = useState<SpuItemDto[]>([]);
  const taobaoIids = taobaoIidskuids.map(i => i.taobaoIid);
  const taobaoSkuIdSet = new Set(taobaoIidskuids.map(i => i.taobaoSkuId));

  useEffect(() => {
    (async () => {
      const itemList = await chatpeerRequest.getByTaoBaoIIds({
        searchTaobaoIids: taobaoIids
      });
      setItems(itemList);
    })();
  }, [taobaoIidskuids.length]);

  if (!items.length) {
    return <span className={className}>{label}</span>;
  }

  return (
    <Popover
      placement="left"
      destroyTooltipOnHide
      overlayClassName={css.pop}
      content={
        <div className={css.goods}>
          <div className={css.goodsTitle}>{title}</div>
          <div className={css.goodsWrap}>
            {items?.length ? (
              <TaobaoItemIdsInfo
                items={items}
                taobaoSkuIdSet={taobaoSkuIdSet}
              />
            ) : (
              <NoDataContent noDataText="未关联商品" />
            )}
          </div>
        </div>
      }
    >
      <span className={className}>{label}</span>
    </Popover>
  );
});
