import React, { memo, useRef } from 'react';
import { Checkbox, CheckboxGroup } from '@/components/widgets/checkbox';
import { Paragraph } from '@/components/widgets/typography';
import MiniImage from '@/components/works/miniImage';
import { approveStatusMap } from '../../constants';
import css from './style.less';
import { SkuWebDto } from '@/types/esItem';
import { combineSkuProperties } from '../../service';

type SkuToolTipProps = {
  itemSkus: SkuWebDto[];
  selectSkus: SkuWebDto[];
  onSelect: (v: SkuWebDto, container?: HTMLElement | null) => void;
};

export const SkuToolTip = memo((props: SkuToolTipProps) => {
  const { itemSkus, selectSkus, onSelect } = props;

  // 让重复校验弹窗出现的时候tooltip不会自动关掉
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <CheckboxGroup value={selectSkus.map(v => v.taobaoSkuId)}>
      <div
        ref={divRef}
        className={css.skuToolTip}
        onClick={e => e.stopPropagation()}
      >
        {itemSkus.map(sku => (
          <div key={sku.taobaoSkuId} className={css.sku}>
            <Checkbox
              value={sku.taobaoSkuId}
              onChange={() => onSelect(sku, divRef.current)}
            />
            <div className={css.imgBox}>
              <div style={{ position: 'relative' }}>
                <MiniImage
                  src={sku.picUrl}
                  size={50}
                  style={{ border: '1px solid #85aeff' }}
                  lazyLoad
                />
                <div className={css.approveStatusMask}>
                  {approveStatusMap[sku.approveStatus]}
                </div>
              </div>
            </div>
            <Paragraph
              style={{ marginBottom: '4px', height: '41px' }}
              ellipsis={{
                rows: 2,
                tooltip: combineSkuProperties(sku.skuProperties)
              }}
            >
              {sku.title}
            </Paragraph>
            {/* <Paragraph ellipsis={{ rows: 1, tooltip: sku.taobaoSkuId }}>
              <span style={{ color: '#818181' }}>SKU编号：</span>
              {sku.taobaoSkuId}
            </Paragraph> */}
            <Paragraph ellipsis={{ rows: 1, tooltip: sku.price }}>
              <span style={{ color: '#818181' }}>SKU价格：</span>
              {sku.price}
            </Paragraph>
            {/* {sku.outerIid ? (
              <Paragraph ellipsis={{ rows: 1, tooltip: sku.outerIid }}>
                <span style={{ color: '#818181' }}>商家SKU：</span>
                {sku.outerIid}
              </Paragraph>
            ) : null} */}
          </div>
        ))}
      </div>
    </CheckboxGroup>
  );
});
